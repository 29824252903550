<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-redpacket_fill"></i> 支持作者
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="plugins-tips">
        如果该框架对你有帮助，那就请作者喝杯咖啡，添加以下作者好友一起交流吧，精品干货，持续输出。
      </div>
      <div>
        <img
          style="width: 200px; margin-left: 10px;"
          src="../assets/img/about-me.pic.jpg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "donate",
};
</script>

<style></style>
